import styled, { css } from "styled-components";

import { FooterStyledProps as Props } from "./Footer.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { tablet } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const sharedTextStyles = css`
  font-size: 1.2rem;
  color: var(--palette-gray-s0-l35);
`;

const FooterStyled = styled.div<Props>`
  padding-top: 4rem;
  padding-bottom: 4.8rem;
  background-color: var(--palette-white);
  border-radius: 6.4rem 6.4rem 0 0;
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;
  font-family: ${primary};

  @media (max-width: ${tablet}px) {
    border-radius: 4.8rem 4.8rem 0 0;
    padding-top: 2.4rem;
    padding-bottom: 8rem;
    margin-bottom: -1.6rem;
  }

  .Footer {
    &__content {
      grid-column: content;
    }

    &__row {
      &:not(:last-child) {
        padding-bottom: 1.6rem;
      }

      &:first-child {
        padding-bottom: 4.8rem;
      }

      @media (max-width: ${tablet}px) {
        &:first-child {
          padding-bottom: 2.4rem;
        }
      }
    }

    &__social {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
        align-items: center;
      }

      &__wrapper {
        display: flex;
        align-items: center;

        @media (max-width: ${tablet}px) {
          padding-top: 3.2rem;
        }
      }

      &__icon {
        list-style: none;

        path {
          fill: var(--palette-orange-s89-l54);
        }

        &:not(:last-child) {
          padding-right: 2rem;
        }
      }
    }

    &__link {
      text-decoration: none;
      cursor: pointer;
    }

    &__caption {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
      }
    }

    &__developed {
      font-weight: 600;
    }

    &__privacy-and-terms {
      display: flex;
      font-size: 1.2rem;
      padding-bottom: 0.8rem;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
        padding-bottom: 2rem;
      }

      &__item {
        text-decoration: none;
        cursor: pointer;
        text-align: center;
        color: var(--palette-black-s0-l10);
        padding-bottom: 0.8rem;

        &:hover {
          color: var(--palette-primary);
        }

        @media (min-width: ${tablet}px) {
          &:not(:last-child)::after {
            content: "|";
            padding: 0 1.4rem;
          }
        }

        &:not(:first-child) {
          padding-left: 0.2rem;
        }
      }
    }

    &__copyright {
      ${sharedTextStyles}

      @media (max-width: ${tablet}px) {
        text-align: center;

        span {
          display: block;
        }
      }
    }

    &__right {
      @media (max-width: ${tablet}px) {
        padding-bottom: 2rem;
        margin: 0 auto;
      }
    }

    &__left {
      ${sharedTextStyles}

      span {
        font-weight: 600;
      }

      @media (max-width: ${tablet}px) {
        margin: 0 auto;
      }
    }
  }
`;

export default FooterStyled;
